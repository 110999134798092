import { Button, Theme } from "@mui/material";
import React, { FC } from "react";
import { muiStylesButtons } from "../muiStylesButtons";
import { SystemStyleObject } from "@mui/system";

interface Props {
  text: string;
  disabled?: boolean;
  event?: () => void;
  error?: boolean;
  customStyles?: SystemStyleObject<Theme>;
  isSubmit?: boolean;
}

const TertiaryButton: FC<Props> = ({
  text,
  disabled,
  event,
  error,
  isSubmit,
  customStyles,
}) => {
  const isDisabled = !!disabled;

  return (
    <Button
      sx={[
        muiStylesButtons.tertiaryButton,
        error ? muiStylesButtons.tertiaryButton.error : {},
        customStyles !== undefined && customStyles,
      ]}
      disabled={isDisabled}
      onClick={event}
      type={isSubmit ? "submit" : "button"}
    >
      {text}
    </Button>
  );
};

export default TertiaryButton;